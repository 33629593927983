<template>
    <v-container class="ma-0 pa-0">
        <!-- <div
            v-if="canUpdatePosts"
            style="margin: 0 auto; max-width: 665px"
        >
            <company-selector
                :company-id-selected="getSelectedCompany"
                @select:company="changeCompanyPosts($event)"
            />
        </div> -->
        <div class="buttons-home-container">
            <div
                v-for="(post, index) in getPostsToShow"
                :key="post.id"
                class="buttons-home"
                :style="isAvailableButton(post, index) ? 'opacity: 1 ' : 'opacity: 0.3 '"
                @click="clickButton(post, index)"
            >
                <base-card
                    :class="'rounded-xl base-card-buttons'"
                    :style="`border: 2px ${getCompany.color_primary || '#00b5e2'} solid !important;`"
                >
                    <div class="buttons-home__image">
                        <!--                      :min-height="competitionIsEnded ? '80px' : '55px'"-->
                        <v-img
                            class="rounded-xl"
                            width="80%"
                            contain
                            :src="getImageRoute + post.attributes.image"
                            min-height="80px"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="blue lighten-5"
                                    />
                                </v-row>
                            </template>
                        </v-img>
                    </div>
                    <v-card-actions class="pa-2 pa-sm-4 d-flex justify-space-between">
                        <v-card-subtitle
                            class="pa-0"
                            style="width: 100%"
                        >
                            <div class="d-flex justify-center">
                                <span class="buttons-post-text">{{ post.attributes.title }}</span>
                            </div>
                        </v-card-subtitle>
                    </v-card-actions>
                </base-card>
            </div>
        </div>

        <slot />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Posts',
    components: {
        /* CompanySelector: () => import('@/components/admin/companies/companySelector')  */
    },
    data: function () {
        return {
            dateNow: Date.now(),
            refreshIntervalId: null
        };
    },
    beforeDestroy() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    },
    methods: {
        isAvailableButton(post, index) {
            return this.generalHelper.isAvailableButton({ post, index, indexShowPost: this.getIndexShowPost });
        },
        clickButton(post, index) {
            if (this.isAvailableButton(post, index)) {
                const url = this.generalHelper.getPostURL({ post });

                this.$router.push(url);
            } else {
                this.$store.dispatch('snackbar/error', this.$t('home.optionLocked'));
            }
        } /* ,
        async changeCompanyPosts(selectedCompany) {
            this.$store.dispatch('loading/start');

            const company = selectedCompany?.id ?? '';
            await this.$store.dispatch('cache/loadPosts', { company });

            await this.$store.dispatch('configGeneral/setConfigKeyValue', { key: 'companyIdSelected', value: company });

            this.$store.dispatch('loading/end');
        } */
    },
    computed: {
        ...mapGetters('cache', ['getPostsOrdered']),
        ...mapGetters(['getIndexShowPost', 'getCompany']),
        ...mapGetters('languages', ['getLang']),
        ...mapGetters('configGeneral', ['getConfigKeyValue']),
        // ...mapGetters('permissions', ['canUpdatePosts']),
        getPostsToShow() {
            return this.getPostsOrdered;
        },
        /* getSelectedCompany() {
            let id = this.getConfigKeyValue.companyIdSelected;
            if (!id && this.getPostsToShow.length) {
                id = this.getPostsToShow[0].attributes['company-id'];
            }

            return id;
        }, */
        getImageRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=posts/images/';
        }
    }
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/octavia-design-system/variables/_colors';
@import '../../assets/scss/octavia-design-system/variables/_base';

.buttons-home-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .buttons-home {
        width: 8rem;
        min-height: 11.7rem;
        padding: rem(7px);
        cursor: pointer;

        .base-card-buttons {
            width: 100%;
            height: 100%;
            padding-top: rem(12px);

            &:hover {
                box-shadow: 0 9px 11px -5px rgba(0, 0, 0, 0.2), 0 18px 28px 2px rgba(0, 0, 0, 0.14),
                    0 7px 34px 6px rgba(0, 0, 0, 0.12) !important;
                border: 2px $secondary solid !important;
            }

            .buttons-home__image {
                display: flex;
                flex-wrap: wrap;
                padding: rem(12px);

                div {
                    max-height: rem(130px);
                }
            }
        }

        .button-border-mark {
            border: 3px #46d5ab solid !important;
        }

        .buttons-post-text {
            text-align: center;
            color: black;
            font-family: $font-family-heading;
            font-size: 0.65rem;
            line-height: 1.3rem;
        }
    }
}

@media (min-width: 380px) {
    .buttons-home-container {
        .buttons-home {
            width: 9.3rem;
            min-height: 13rem;
            padding: rem(8px);

            .buttons-post-text {
                font-size: 0.75rem;
            }
        }
    }
}

@media (min-width: 570px) {
    .buttons-home-container {
        .buttons-home {
            width: 12.5rem;
            min-height: 14.5rem;
            padding: rem(12px);

            .buttons-post-text {
                font-size: 0.835rem;
                line-height: 1.5rem;
            }
        }
    }
}
</style>
